import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let ClickableCard = class ClickableCard extends Vue {
    onCardClick() {
        if (this.propReadOnly) {
            return;
        }
        if (this.isSelected) {
            if (this.propObjectToReturn) {
                this.$emit('input', []);
                return;
            }
            const arr = [...this.value];
            const index = arr.findIndex((value) => value === this.propCardTitle);
            arr.splice(index, 1);
            this.$emit('input', arr);
            return;
        }
        if (this.propSingleSelection) {
            if (this.propObjectToReturn) {
                this.$emit('input', [this.propCardTitle, this.propObjectToReturn]);
            }
            else {
                this.$emit('input', [this.propCardTitle]);
            }
        }
        else {
            const arr = [...this.value];
            arr.push(this.propCardTitle);
            this.$emit('input', arr);
        }
        this.propOnClickAction();
    }
    get isSelected() {
        if (!this.value) {
            return false;
        }
        return this.value.includes(this.propCardTitle);
    }
    mounted() {
        if (!Array.isArray(this.value)) {
            this.$emit('input', []);
        }
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], ClickableCard.prototype, "propReadOnly", void 0);
__decorate([
    Prop({ type: String, default: '' })
], ClickableCard.prototype, "propCardTitle", void 0);
__decorate([
    Prop({ type: String, default: '' })
], ClickableCard.prototype, "propCardSubtitle", void 0);
__decorate([
    Prop({ type: String, default: '' })
], ClickableCard.prototype, "propCardText", void 0);
__decorate([
    Prop({ type: String, default: '' })
], ClickableCard.prototype, "propChipText", void 0);
__decorate([
    Prop({ type: Object, default: null })
], ClickableCard.prototype, "propObjectToReturn", void 0);
__decorate([
    Prop({
        type: [Function, Promise],
        default: () => {
            return;
        },
    })
], ClickableCard.prototype, "propOnClickAction", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], ClickableCard.prototype, "propLoading", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], ClickableCard.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], ClickableCard.prototype, "propSingleSelection", void 0);
ClickableCard = __decorate([
    Component
], ClickableCard);
export default ClickableCard;
