import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
let OpcaoTurmaOuReposicao = class OpcaoTurmaOuReposicao extends Vue {
    constructor() {
        super(...arguments);
        this.selectedItem = null;
    }
    onSelectedItemChanged(val) {
        this.$emit('input', val);
    }
};
__decorate([
    Prop({ type: Array, default: () => ([]) })
], OpcaoTurmaOuReposicao.prototype, "propItems", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], OpcaoTurmaOuReposicao.prototype, "propOutraReposicao", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], OpcaoTurmaOuReposicao.prototype, "propTurma", void 0);
__decorate([
    Watch('selectedItem')
], OpcaoTurmaOuReposicao.prototype, "onSelectedItemChanged", null);
OpcaoTurmaOuReposicao = __decorate([
    Component({
        components: {
            ButtonComponent,
            DateFieldComponent
        }
    })
], OpcaoTurmaOuReposicao);
export default OpcaoTurmaOuReposicao;
