import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let PrimeiraEtapaPopup = class PrimeiraEtapaPopup extends Vue {
};
__decorate([
    Prop({ default: () => ({ counts: [], quantidade: 0, reposicoes: [] }) })
], PrimeiraEtapaPopup.prototype, "propFirstStep", void 0);
PrimeiraEtapaPopup = __decorate([
    Component({})
], PrimeiraEtapaPopup);
export default PrimeiraEtapaPopup;
