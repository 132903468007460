import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let OpcaoButton = class OpcaoButton extends Vue {
};
__decorate([
    Prop({ type: Boolean, default: false })
], OpcaoButton.prototype, "disabled", void 0);
__decorate([
    Prop({ type: String, required: true })
], OpcaoButton.prototype, "label", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], OpcaoButton.prototype, "selected", void 0);
__decorate([
    Prop({ type: Function, required: true })
], OpcaoButton.prototype, "handleClick", void 0);
OpcaoButton = __decorate([
    Component
], OpcaoButton);
export default OpcaoButton;
