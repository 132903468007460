var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{attrs:{"flat":""}},_vm._l((_vm.propItems),function(turma,idx){return _c('v-expansion-panel',{key:idx,staticClass:"shadow-expansion mt-4"},[_c('v-expansion-panel-header',[_c('v-list',[_c('v-list-item',{staticClass:"articulat-extrabold"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Turma")]),_c('v-list-item-subtitle',{staticClass:"mt-4"},[_c('OpenNewTabComponent',{staticClass:"purplemain--text",attrs:{"propObjectLabel":("" + (turma.codigo_referencia)),"propObjectId":turma.turma_id,"propToolName":'Turma'}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Livro")]),_c('v-list-item-subtitle',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(turma.livro_nome)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Presença")]),_c('v-list-item-subtitle',{staticClass:"articulat-medium mt-2"},[_c('v-chip',{class:_vm.propGetFaltas(turma.turma_aluno_id) > 0
                        ? 'red400 red900--text'
                        : 'green300 green900--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.propGetFaltas(turma.turma_aluno_id))+" "+_vm._s(_vm.propGetFaltas(turma.turma_aluno_id) === 1 ? 'Falta' : 'Faltas')+" ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Status do aluno na turma")]),_c('v-list-item-subtitle',{staticClass:"articulat-medium mt-2"},[_c('v-chip',{attrs:{"small":"","color":"green700","outlined":""}},[_vm._v(" "+_vm._s(_vm._.find( _vm.getStatusDoAlunoOptions(), function (s) { return s.id == turma.status_do_aluno_na_turma_id; } ).name)+" ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Status da turma")]),_c('v-list-item-subtitle',{staticClass:"articulat-medium mt-4"},[_vm._v(" "+_vm._s(_vm._.find( _vm.turmaStatusOptions(), function (item) { return item.id == turma.turma_status_id; } ).name)+" ")])],1)],1)],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"mb-4 pa-4 table-ticket articulat-extrabold",attrs:{"loading":_vm.propLoading,"dense":"","headers":_vm.propPedagogicoHeaders(turma),"items":_vm.propGetAtividadesByTurmaAluno(turma.turma_aluno_id),"fixed-header":"","height":"50vh","footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.atividade_data_executada",fn:function(ref){
                        var value = ref.value;
return [_c('v-chip',{staticClass:"purplemain--text articulat-medium",attrs:{"color":"gray50"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")])]}},{key:"item.presenca",fn:function(ref){
                        var item = ref.item;
return [(item.presenca === 'Falta')?_c('v-chip',{staticClass:"articulat-medium red900--text",attrs:{"color":"red400"}},[_vm._v(" "+_vm._s(item.presenca)+" ")]):(item.presenca === 'Reposição agendada')?_c('v-chip',{staticClass:"articulat-medium purplemain--text",attrs:{"color":"purple300"}},[_vm._v(" Reposição agendada pela escola ")]):(item.presenca === 'Presente')?_c('v-chip',{staticClass:"articulat-medium green900--text",attrs:{"color":"green300"}},[_vm._v(" "+_vm._s(item.presenca)+" ")]):_c('v-chip',{staticClass:"articulat-medium green900--text"},[_vm._v(" "+_vm._s(item.presenca)+" ")])]}}],null,true)}),_c('div',{staticClass:"text-end"},[(turma.status_do_aluno_na_turma_id === 1 &&
              _vm.userActionsEvadirAlunoTurmaAllow
              )?_c('ButtonComponent',{staticClass:"pr-2",attrs:{"propColor":"redmain","propOutlined":"","propTitle":"Evadir aluno","propClick":function () { return _vm.propEvadirAluno(turma.turma_aluno_id); }}}):_vm._e(),_c('ButtonComponent',{attrs:{"propColor":"purplemain","propLoading":_vm.loadingBoletim,"propTitle":"Imprimir boletim","propClick":function () { return _vm.imprimirBoletim(turma.turma_aluno_id); }}})],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }