import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { entidadeStore, mainStore } from '@/store';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
// import { Entidade } from '@/interfaces/entidade';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => {
        // mudada a função do validate para funcionar com o objeto pois '!!{}' retornava true
        if (value) {
            return Object.keys(value).length > 0;
        }
        return false;
    },
}, { immediate: false });
let SearchEntidadeComponent = class SearchEntidadeComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loadingEntidade = false;
        this.searchEntidade = null;
        this.selectedEntidade = {};
        this.entidadesOptions = [];
        this.validatorErrors = [];
        this.timer = null;
        this.firstTime = true;
        this.radioGroup = { label: '', field: '' };
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    // foi preciso chamar o validate de dentro do componente nesse caso, de fora ele não estava rodando
    async validateField() {
        const valid = this.value && this.value.id
            ? Object.keys(this.selectedEntidade).length > 0
            : false;
        if (!valid) {
            mainStore.addErrorToasted({
                // @ts-ignore
                toasted: this.$toasted,
                errors: this.propCustomErrorMessage
                    ? [this.propCustomErrorMessage]
                    : [`${this.propTitle} deve ser preenchido!`],
            });
            this.validatorErrors = this.propCustomErrorMessage
                ? [this.propCustomErrorMessage]
                : [`${this.propTitle} deve ser preenchido!`];
        }
        else {
            this.validatorErrors = [];
        }
        return valid;
    }
    async clearErrors() {
        this.validatorErrors = [];
    }
    customFilter(item, queryText, itemText) {
        if (!queryText) {
            return false;
        }
        if (item.id == 0) {
            return false;
        }
        let textOne;
        if (this.radioGroup.label && item[this.radioGroup.label.toLowerCase()]) {
            textOne = item[this.radioGroup.label.toLowerCase()]
                .replaceAll(' ', '')
                .toLocaleLowerCase();
        }
        else {
            textOne = item.nome.replaceAll(' ', '').toLocaleLowerCase();
        }
        const searchText = queryText
            .replace(/[\(\)\-\s]/g, '')
            .replaceAll(' ', '')
            .toLocaleLowerCase();
        // esta é a função original do filter do vuetify
        // itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        if (!item.extra) {
            return textOne.indexOf(searchText) > -1;
        }
        const textTwo = item.extra.toLowerCase();
        return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    }
    async onSearchEntidade(val) {
        if (this.departamento_id) {
            return (val &&
                val !== this.selectedEntidade &&
                this.querySelections(val, this.departamento_id));
        }
        else {
            return (val && val !== this.selectedEntidade && this.querySelections(val, null));
        }
    }
    async onChangeEntidade(val) {
        if (this.selectedEntidade) {
            // @ts-ignore
            this.id = this.selectedEntidade.id;
            // @ts-ignore
            this.nome = this.selectedEntidade.nome;
            // @ts-ignore
            this.full_name = this.selectedEntidade.nome;
            // @ts-ignore
            this.tipo = this.selectedEntidade.tipo;
        }
        else {
            this.$emit('input', null);
        }
        this.$emit('input', val);
    }
    addOption(item) {
        if (!item) {
            this.entidadesOptions = [];
            this.selectedEntidade = null;
        }
        this.entidadesOptions.push(item);
        this.selectedEntidade = { ...item };
    }
    async mounted() {
        this.loadingEntidade = true;
        if (this.value && this.value.id > 0 && Object.keys(this.value).length > 0) {
            this.selectedEntidade = { ...this.value };
            this.entidadesOptions = [this.value];
        }
        if (this.propDefaultEntityField) {
            this.radioGroup = this.propDefaultEntityField;
        }
        this.loadingEntidade = false;
    }
    async querySelections(v, departamento_id) {
        this.loadingEntidade = true;
        // Simulated ajax query
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
            if (departamento_id) {
                this.entidadesOptions = await entidadeStore.getEntidadesByField({
                    types: this.propEntityType,
                    value: v,
                    departamento_id,
                    show_inactive: this.propShowInactive,
                    radio_label: this.radioGroup.label,
                    radio_field: this.radioGroup.field,
                });
            }
            else {
                this.entidadesOptions = await entidadeStore.getEntidadesByField({
                    types: this.propEntityType,
                    value: v,
                    departamento_id: 0,
                    show_inactive: this.propShowInactive,
                    radio_label: this.radioGroup.label,
                    radio_field: this.radioGroup.field,
                });
            }
            this.loadingEntidade = false;
        }, 1000);
    }
};
__decorate([
    Prop()
], SearchEntidadeComponent.prototype, "value", void 0);
__decorate([
    Prop({ default: 'Entidade' })
], SearchEntidadeComponent.prototype, "propTitle", void 0);
__decorate([
    Prop()
], SearchEntidadeComponent.prototype, "departamento_id", void 0);
__decorate([
    Prop({ default: false })
], SearchEntidadeComponent.prototype, "propDisabled", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], SearchEntidadeComponent.prototype, "propReadonly", void 0);
__decorate([
    Prop({ default: false })
], SearchEntidadeComponent.prototype, "propDense", void 0);
__decorate([
    Prop({ default: false })
], SearchEntidadeComponent.prototype, "propOutlined", void 0);
__decorate([
    Prop({ default: false })
], SearchEntidadeComponent.prototype, "propDisableValidator", void 0);
__decorate([
    Prop({ default: '' })
], SearchEntidadeComponent.prototype, "propCustomErrorMessage", void 0);
__decorate([
    Prop({ default: true })
], SearchEntidadeComponent.prototype, "propShowInactive", void 0);
__decorate([
    Prop({ default: '' })
], SearchEntidadeComponent.prototype, "propCustomClass", void 0);
__decorate([
    Prop({ default: [] })
], SearchEntidadeComponent.prototype, "propEntityType", void 0);
__decorate([
    Prop({ default: () => [] })
], SearchEntidadeComponent.prototype, "propEntityFields", void 0);
__decorate([
    Prop({ default: null })
], SearchEntidadeComponent.prototype, "propDefaultEntityField", void 0);
__decorate([
    Watch('searchEntidade')
], SearchEntidadeComponent.prototype, "onSearchEntidade", null);
__decorate([
    Watch('selectedEntidade')
], SearchEntidadeComponent.prototype, "onChangeEntidade", null);
SearchEntidadeComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
    })
], SearchEntidadeComponent);
export default SearchEntidadeComponent;
