import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let TextExpandableComponent = class TextExpandableComponent extends Vue {
    constructor() {
        super(...arguments);
        this.expanded = false;
    }
    get showButton() {
        let retorno = false;
        if (this.$slots.default) {
            if (this.$slots.default[0].text) {
                if (this.$slots.default[0].text.trim() != '') {
                    retorno = true;
                }
            }
        }
        return retorno;
    }
    expand(ev, value) {
        ev.stopPropagation();
        this.expanded = value;
    }
};
TextExpandableComponent = __decorate([
    Component({})
], TextExpandableComponent);
export default TextExpandableComponent;
