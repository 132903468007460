import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let ExpandirDialog = class ExpandirDialog extends Vue {
};
__decorate([
    Prop({ type: String })
], ExpandirDialog.prototype, "propValue", void 0);
ExpandirDialog = __decorate([
    Component
], ExpandirDialog);
export default ExpandirDialog;
