import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { turmaAlunoAtividadeStore } from '@/utils/store-accessor';
import ButtonComponent from '@/components/ButtonComponent.vue';
let NaoRepor = class NaoRepor extends Vue {
    constructor() {
        super(...arguments);
        this.motivoCancelamento = '';
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    async reporAula(item) {
        const ids = [item.turma_aluno_atividade_id];
        turmaAlunoAtividadeStore.naoIraRepor({
            ids,
            observacao: this.motivoCancelamento,
        });
        this.onCancel();
    }
};
__decorate([
    Prop({ type: Object, default: () => ({}) })
], NaoRepor.prototype, "propItems", void 0);
NaoRepor = __decorate([
    Component({
        components: {
            ButtonComponent
        }
    })
], NaoRepor);
export default NaoRepor;
