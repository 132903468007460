import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { turmaAlunoAtividadeStore, salaStore } from '@/store';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let OpcaoNovaData = class OpcaoNovaData extends Vue {
    constructor() {
        super(...arguments);
        this.reposicoesNovaData = [];
        this.nova_reposicao_data = null;
        this.isloadingHorarios = false;
        this.selectPeriod = '';
        this.selectedContent = null;
    }
    onselectedContentChanged(val) {
        this.$emit('input', val);
    }
    async consultaHorariosDisponiveis() {
        if (!this.nova_reposicao_data) {
            return;
        }
        this.isloadingHorarios = true;
        this.reposicoesNovaData = await turmaAlunoAtividadeStore.getHorariosDisponiveis({
            data: this.nova_reposicao_data,
            idioma: this.propItems.idioma || 'Inglês',
        });
        this.isloadingHorarios = false;
    }
    get filteredHorarios() {
        if (Object.keys(this.reposicoesNovaData).length === 0) {
            return {};
        }
        let filtered = {};
        if (this.selectPeriod.includes('morning')) {
            filtered = { ...filtered, ..._.pickBy(this.reposicoesNovaData, (v, k) => Number(k) < 720) };
        }
        if (this.selectPeriod.includes('afternoon')) {
            filtered = { ...filtered, ..._.pickBy(this.reposicoesNovaData, (v, k) => Number(k) >= 720 && Number(k) < 1080) };
        }
        if (this.selectPeriod.includes('evening')) {
            filtered = { ...filtered, ..._.pickBy(this.reposicoesNovaData, (v, k) => Number(k) >= 1080) };
        }
        return filtered;
    }
    convertMinutesToTimeString(minutes) {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours}h${mins === 0 ? '' : `${mins < 10 ? '0' : ''}${mins}`}`;
    }
    salasFiltradas(ids) {
        return _.filter(this.salasOptions, (item) => {
            return ids.includes(item.id);
        });
    }
    get salasOptions() {
        return salaStore.salas;
    }
    onSelectChange(content) {
        if (this.selectedContent && this.selectedContent !== content) {
            this.selectedContent.sala_id_selected = null; // Limpa a seleção anterior
        }
        this.selectedContent = content;
    }
    onRadioChange(content) {
        if (this.selectedContent !== content) {
            this.selectedContent = content;
        }
    }
};
__decorate([
    Prop({ type: Object, default: () => ({}) })
], OpcaoNovaData.prototype, "propItems", void 0);
__decorate([
    Watch('selectedContent')
], OpcaoNovaData.prototype, "onselectedContentChanged", null);
OpcaoNovaData = __decorate([
    Component({
        components: {
            ButtonComponent,
            ValidationObserver,
            ValidationProvider,
        }
    })
], OpcaoNovaData);
export default OpcaoNovaData;
