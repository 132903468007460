import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ocorrenciaStore, analyticsStore, filtroPorTabelaStore, userActionStore, } from '@/store';
import ButtonComponent from '@/components/ButtonComponent.vue';
import OcorrenciaCreate from './OcorrenciaCreate.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import ExibirMaisFiltros from './ExibirMaisFiltros.vue';
import ExportarDados from './ExportarDados.vue';
import OcorrenciaTabela from './OcorrenciaTabela.vue';
import ShowDialog from '@/components/ShowDialog.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let OcorrenciaRealizada = class OcorrenciaRealizada extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Data',
                value: 'created_at',
                table: 'ocorrencia',
                show: true,
                type: 'date',
            },
            {
                text: 'Criado por',
                table: 'ocorrencia',
                value: 'created_by_id',
                show: true,
            },
            {
                text: 'Departamento',
                table: 'ocorrencia',
                value: 'departamento_id',
                show: true,
            },
            {
                text: 'Contato',
                table: 'objecao',
                value: 'objecao_nome',
                field: 'nome',
                show: true,
            },
            {
                text: 'Assunto',
                table: 'objecao',
                value: 'assunto',
                show: true,
            },
            {
                text: 'Entidade',
                table: 'ocorrencia',
                value: 'entidade_id',
                type: 'date',
                show: true,
            },
            {
                text: 'Observação',
                table: 'ocorrencia',
                value: 'observacao',
                type: 'str',
                show: true,
            },
            {
                text: 'Ações',
                value: 'actions',
                show: true,
            },
        ];
        this.tabelaRef = '';
        this.loadingSave = false;
        this.selectCheckbox = null;
        this.search = '';
        this.entidadeLabel = '';
        this.loading = true;
        this.filtroEntidadeSelected = {};
        this.selectPeriodDate = [];
        this.carregandoTotals = false;
        this.passouBuscaInicial = false;
        this.fezBuscaComData = false;
    }
    get totals() {
        return analyticsStore.totals;
    }
    get headersExibir() {
        return analyticsStore.headers;
    }
    get permiteExportarDados() {
        return userActionStore.permiteExportarDados;
    }
    async onChangeSelectAllCheckbox(val) {
        if (this.loadingSave) {
            return;
        }
        this.loadingSave = true;
        await filtroPorTabelaStore.filtroPorTabelaBuscaFiltroSessao('list_ocorrencias');
        this.headersExibir.forEach((header) => {
            header.show = val.includes(header.value);
        });
        const items = this.headersExibir.filter((item) => item.show);
        await filtroPorTabelaStore.filtroPorTabelaCreate({
            items,
            table_name: 'list_ocorrencias',
        });
        this.loadingSave = false;
    }
    handleExibirMaisFiltros() {
        const form = this.$refs.showExibirMaisFiltros;
        form.show();
    }
    handleExportarDados() {
        const form = this.$refs.showExportarDados;
        form.show();
    }
    handleBack() {
        const form = this.$refs.showExportarDados;
        form.close();
    }
    handleCreateOcorrencia() {
        const form = this.$refs.showCreateOcorrencia;
        form.show();
    }
    async onCancel() {
        if (Object.keys(this.totals).length > 0) {
            await this.buscarTotals();
        }
        this.loading = true;
        const createOcorrencia = this.$refs.showCreateOcorrencia;
        createOcorrencia.close();
        this.buscar();
    }
    async doSearchTotals() {
        const payload = {
            data_inicial: null,
            data_final: null,
            entidade_id: this.propEntidadeId ? this.propEntidadeId : null,
            entidade_tipo: this.propEntidadeTipo ? this.propEntidadeTipo : null,
        };
        if (this.selectPeriodDate.length > 0 &&
            !this.propShowAll &&
            !this.propEntidadeId &&
            this.fezBuscaComData) {
            payload.data_inicial = this.selectPeriodDate[0];
            payload.data_final = this.selectPeriodDate[1];
        }
        if (this.filtroEntidadeSelected) {
            if (Object.keys(this.filtroEntidadeSelected).length > 0) {
                // @ts-ignore
                payload.entidade_id = this.filtroEntidadeSelected.id;
            }
        }
        return await analyticsStore.getAnalyticsOcorrencia(payload);
    }
    async buscar(mounted = false) {
        if (this.selectPeriodDate.length === 0 &&
            !mounted &&
            !this.propShowAll &&
            !this.propEntidadeId) {
            // usando esse timeout para caso o componente de período não tenha dado um valor,
            // a função é chamada de volta depois de 10ms, para dar tempo de ter um valor no período
            this.passouBuscaInicial = true;
            setTimeout(() => {
                this.buscar(mounted);
            }, 10);
            return;
        }
        const filters = {};
        if (this.selectPeriodDate.length > 0 &&
            !mounted &&
            !this.propShowAll &&
            !this.propEntidadeId) {
            filters['ocorrencia.created_at'] = {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            };
            this.fezBuscaComData = true;
        }
        if (this.propEntidadeId) {
            filters['ocorrencia.entidade_id'] = {
                type: 'int',
                value: [this.propEntidadeId],
            };
        }
        if (this.filtroEntidadeSelected) {
            if (Object.keys(this.filtroEntidadeSelected).length > 0) {
                filters['ocorrencia.entidade_id'] = {
                    type: 'int',
                    // @ts-ignore
                    value: [this.filtroEntidadeSelected.id],
                };
            }
        }
        if (this.propEntidadeTipo) {
            filters['ocorrencia.entidade_tipo'] = {
                type: 'str',
                value: [this.propEntidadeTipo],
            };
        }
        if (this.propEntidadeTipo == 'company') {
            filters['ocorrencia_relacionamento_unidade.unidade_id'] = {
                type: 'int',
                value: [this.propEntidadeId],
            };
        }
        analyticsStore.setDefaultFilters(filters);
        await analyticsStore.executeSearchItems();
        if (this.fezBuscaComData) {
            this.buscarTotals();
        }
    }
    async buscarTotals() {
        this.carregandoTotals = true;
        await this.doSearchTotals();
        this.carregandoTotals = false;
    }
    async mounted() {
        const refs = this.$refs;
        const ocorrenciaTableComponent = this.$refs.ocorrenciaTabela;
        this.tabelaRef = ocorrenciaTableComponent.$refs.tabela;
        if (this.propEntidadeLabel) {
            this.entidadeLabel = this.propEntidadeLabel;
        }
        else {
            this.entidadeLabel = '';
        }
        analyticsStore.initAnalytics();
        analyticsStore.setHeaders(this.headers);
        analyticsStore.setFnSearchItems(ocorrenciaStore.getOcorrenciasQuery);
        await filtroPorTabelaStore.filtroPorTabelaBuscaFiltroSessao('list_ocorrencias');
        this.selectCheckbox = this.headersExibir
            .filter((header, index) => header.show && ![0, 5, 7].includes(index))
            .map((header) => header.value);
        this.buscar(true);
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Number })
], OcorrenciaRealizada.prototype, "propEntidadeId", void 0);
__decorate([
    Prop({ type: String })
], OcorrenciaRealizada.prototype, "propEntidadeTipo", void 0);
__decorate([
    Prop({ type: String, default: '' })
], OcorrenciaRealizada.prototype, "propEntidadeLabel", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], OcorrenciaRealizada.prototype, "propShowAll", void 0);
OcorrenciaRealizada = __decorate([
    Component({
        components: {
            ButtonComponent,
            OcorrenciaCreate,
            EntidadeLinkComponent,
            ExportarDados,
            ExibirMaisFiltros,
            OcorrenciaTabela,
            ShowDialog,
            ValidationObserver,
            ValidationProvider,
        },
    })
], OcorrenciaRealizada);
export default OcorrenciaRealizada;
