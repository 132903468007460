import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import { alunoStore, userActionStore } from '@/store';
let TodasAtividades = class TodasAtividades extends Vue {
    constructor() {
        super(...arguments);
        this.loadingBoletim = false;
    }
    get userActionsEvadirAlunoTurmaAllow() {
        return userActionStore.evadirAlunoTurma;
    }
    async imprimirBoletim(turmaAlunoId) {
        if (this.loadingBoletim) {
            return;
        }
        this.loadingBoletim = true;
        const res = await alunoStore.getBoletim(turmaAlunoId);
        if (res.data) {
            if (res.headers['content-type'].includes('html')) {
                const html = res.data;
                const w = window.open();
                w.document.write(html);
                setTimeout(() => {
                    w.print();
                }, 1000);
            }
            else {
                const uri = 'data:image/png;base64,' + res.data;
                const a = document.createElement('a');
                a.href = uri;
                a.download = 'Boletim.png';
                a.click();
            }
        }
        this.loadingBoletim = false;
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], TodasAtividades.prototype, "propItems", void 0);
__decorate([
    Prop({ type: Function, required: true })
], TodasAtividades.prototype, "propGetFaltas", void 0);
__decorate([
    Prop({ type: Function, required: true })
], TodasAtividades.prototype, "propGetAtividadesByTurmaAluno", void 0);
__decorate([
    Prop({ type: Function, required: true })
], TodasAtividades.prototype, "propPedagogicoHeaders", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], TodasAtividades.prototype, "propLoading", void 0);
__decorate([
    Prop({ type: Function, required: true })
], TodasAtividades.prototype, "propEvadirAluno", void 0);
TodasAtividades = __decorate([
    Component({
        components: {
            ButtonComponent,
            OpenNewTabComponent
        },
    })
], TodasAtividades);
export default TodasAtividades;
