import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
let ExibirMaisFiltros = class ExibirMaisFiltros extends Vue {
    constructor() {
        super(...arguments);
        this.selectPeriodDate = null;
        this.filtroTipoEntidadeSelected = [];
    }
    onSelectPeriodDate(val) {
        this.$emit('input', val);
    }
    buscar() {
        this.$emit('on-buscar');
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], ExibirMaisFiltros.prototype, "propShowAll", void 0);
__decorate([
    Prop()
], ExibirMaisFiltros.prototype, "propEntidadeId", void 0);
__decorate([
    Prop()
], ExibirMaisFiltros.prototype, "propEntidadeTipo", void 0);
__decorate([
    Prop()
], ExibirMaisFiltros.prototype, "propItems", void 0);
__decorate([
    Watch('selectPeriodDate')
], ExibirMaisFiltros.prototype, "onSelectPeriodDate", null);
ExibirMaisFiltros = __decorate([
    Component({
        components: {
            GenericListTotalCardComponent,
            PeriodSelectorComponent,
            SearchEntidadeComponent,
        },
    })
], ExibirMaisFiltros);
export default ExibirMaisFiltros;
