import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { analyticsStore, ocorrenciaStore, userToolStore } from '@/store';
import ButtonComponent from '@/components/ButtonComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import ExpandirDialog from './ExpandirDialog.vue';
import ShowDialog from '@/components/ShowDialog.vue';
import TarefaChangeStatusComponentNew from '@/components/TarefaChangeStatusComponentNew.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import _ from 'lodash';
let OcorrenciaTabela = class OcorrenciaTabela extends Vue {
    constructor() {
        super(...arguments);
        this.selectedOcorrenciaShowAnexos = null;
        this.loadingAnexos = false;
        this.anexosSelected = [];
        this.ocorrenciaTarefaId = null;
        this.tarefaRealizadaDialog = false;
        this.tarefaCanceladaDialog = false;
        this.selectedItem = {};
        this.anexosHeaders = [
            { text: 'Nome', value: 'blob.name' },
            { text: 'Tamanho', value: 'tamanho' },
            { text: 'Ações', value: 'actions' },
        ];
    }
    get headers() {
        return analyticsStore.headers;
    }
    get headersFiltered() {
        return _.filter(this.headers, (item) => item.show);
    }
    get items() {
        return analyticsStore.items;
    }
    get loading() {
        return analyticsStore.loading;
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed['Gestão de ocorrências']) {
            return userToolStore.userToolsIndexed['Gestão de ocorrências'][0]
                .allow_delete;
        }
    }
    handleShowTarefa(item) {
        this.selectedItem = item;
        const form = this.$refs.showTarefa;
        form.show();
    }
    async tarefaRealizada(id) {
        this.ocorrenciaTarefaId = id;
        this.tarefaRealizadaDialog = true;
    }
    async tarefaCancelada(id) {
        this.ocorrenciaTarefaId = id;
        this.tarefaCanceladaDialog = true;
    }
    getAnexoSize(size) {
        if (size / 1000 < 1000) {
            const value = (size / 1000).toFixed(2);
            return `${value} kB`;
        }
        else if (size / 1000 >= 1000) {
            const value = (size / 1000000).toFixed(2);
            return `${value} MB`;
        }
    }
    async deleteItem(item) {
        this.$swal({
            title: 'Deseja realmente excluir a ocorrência?',
            text: 'Ao deletar uma ocorrência, as tarefas e anexos a ela atrelados serão perdidos!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await ocorrenciaStore.deleteOcorrencia(item.id);
                this.$emit('on-buscar');
            }
        });
    }
    async excluirAnexo(item) {
        const payload = {
            full_name: item.blob.full_name,
            entidade_tipo: this.selectedOcorrenciaShowAnexos.entidade_tipo,
        };
        await ocorrenciaStore.deleteOcorrenciaAnexo(payload);
        await this.onClickMostrarAnexos(this.selectedOcorrenciaShowAnexos);
    }
    async baixarAnexo(item) {
        window.open(item.download_url, '_blank').focus();
    }
    async onClickMostrarAnexos(item) {
        this.loadingAnexos = true;
        this.selectedOcorrenciaShowAnexos = item;
        let anexos = [];
        anexos = await ocorrenciaStore.getOcorrenciaAnexos(item);
        this.anexosSelected = anexos;
        const form = this.$refs.showAnexos;
        form.show();
        this.loadingAnexos = false;
    }
};
__decorate([
    Prop({ type: String, default: '' })
], OcorrenciaTabela.prototype, "propSearch", void 0);
__decorate([
    Prop({ type: Number })
], OcorrenciaTabela.prototype, "propEntidadeId", void 0);
OcorrenciaTabela = __decorate([
    Component({
        components: {
            ButtonComponent,
            EntidadeLinkComponent,
            ExpandirDialog,
            ShowDialog,
            TarefaChangeStatusComponentNew,
            TextExpandableComponent,
        },
    })
], OcorrenciaTabela);
export default OcorrenciaTabela;
