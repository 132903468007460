import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mainStore } from '@/utils/store-accessor';
import { api } from '@/api';
import JSZip from 'jszip';
// @ts-ignore
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
let UploadFilesComponent = class UploadFilesComponent extends Vue {
    constructor() {
        super(...arguments);
        this.selected = [];
        this.label = 'Enviar anexo';
        this.headers = [
            {
                text: 'Nome do Arquivo',
                value: 'blob.name',
                align: 'left',
            },
            {
                text: 'Tamanho do arquivo',
                value: 'blob.size',
                align: 'left',
            },
            {
                text: 'Criado em',
                value: 'blob.created',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
                align: 'left',
            },
        ];
        this.items = [];
        this.loadingData = false;
        this.files = [];
        this.modelFiles = [];
        this.uploadingFiles = '';
        this.selectFilesError = false;
        this.isCompressing = false;
    }
    get currentUser() {
        return mainStore.userProfile;
    }
    alterar(val) {
        this.label += val.trim() === '' ? '' : ' (' + val + ')';
    }
    async openFiles() {
        const el = document.getElementById('fileInput');
        el.click();
    }
    selectFile(archives) {
        this.files = [];
        this.selectFilesError = false;
        const maxSize = this.propMaxSize * Math.pow(10, 6);
        for (let i = 0; i < archives.length; i++) {
            let error = false;
            // @ts-ignore
            if (this.modelFiles[i].size > maxSize) {
                error = true;
                this.selectFilesError = true;
            }
        }
        if (this.selectFilesError) {
            mainStore.addErrorToasted({
                // @ts-ignore
                toasted: this.$toasted,
                errors: [`O tamanho máximo para arquivos é de ${this.propMaxSize} MB`],
            });
            return;
        }
        for (let i = 0; i < archives.length; i++) {
            this.files.push({
                file: this.modelFiles[i],
                uploaded: false,
                sizeError: false,
            });
        }
        this.$emit('input', this.files);
    }
    async openAllDownloadLink() {
        const component = this;
        component.isCompressing = true;
        const zipFilename = component.propDownloadTitle;
        const zip = new JSZip();
        let count = 0;
        const urls = component.selected.map((i) => i.download_url);
        component.items.forEach((item) => {
            item.isSelectable = false;
        }); // Desativa seleção da table
        for await (const url of urls) {
            const filename = url
                .substring(url.indexOf('knn-master') + 23, url.indexOf('?X-Goog-Algorithm'))
                .replace(/%20/g, ' ');
            const data = await JSZipUtils.getBinaryContent(url);
            zip.file(filename, data, { binary: true });
            count++;
            if (count === urls.length) {
                zip.generateAsync({ type: 'blob' }).then((content) => {
                    saveAs(content, zipFilename);
                    component.isCompressing = false;
                    component.items.forEach((item) => {
                        item.isSelectable = true;
                    }); // Reativa seleção
                    component.selected = []; // Limpa seleção
                });
            }
        }
    }
    openDownloadLink(item) {
        const element = document.createElement('a');
        element.setAttribute('href', item.download_url);
        if (item.blob.full_name.includes('pdf') ||
            item.blob.full_name.includes('png') ||
            item.blob.full_name.includes('jpg') ||
            item.blob.full_name.includes('jpeg')) {
            element.setAttribute('target', '_blank');
        }
        else {
            element.setAttribute('style', 'display:none;');
        }
        element.click();
        element.remove();
    }
    formatSize(size) {
        if (size < 1000) {
            return `${(size / 1).toFixed(0)} B`;
        }
        else if (size / Math.pow(10, 3) < 1000) {
            return `${(size / Math.pow(10, 3)).toFixed(2)} Kb`;
        }
        else if (size / Math.pow(10, 6) < 1000) {
            return `${(size / Math.pow(10, 6)).toFixed(2)} Mb`;
        }
        else if (size / Math.pow(10, 9) < 1000) {
            return `${(size / Math.pow(10, 9)).toFixed(2)} Gb`;
        }
    }
    async deleteSwal(item) {
        this.$swal({
            title: 'Confirma a exclusão do arquivo?',
            text: 'Após a exclusão do arquivo o mesmo não poderá ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.deleteObjectFromBucket(item);
            }
        });
    }
    async deleteObjectFromBucket(item) {
        try {
            // @ts-ignore
            const response = await api.deleteObjectFromBucket(mainStore.token, this.propBucketName || this.propRelatedTableName, item.blob.full_name);
            if (response) {
                this.items = await this.getBucketObjectsByPrefix();
                return response.data;
            }
        }
        catch (e) {
            // @ts-ignore
            await mainStore.checkApiError(e);
        }
    }
    removeFile(index) {
        this.modelFiles.splice(index, 1);
        this.files.splice(index, 1);
        this.selectFile(this.modelFiles);
    }
    async uploadFiles() {
        // this.uploadingFiles = "running";
        //
        // for (let i = 0; i < this.files.length; i++) {
        //   let formData = new FormData();
        //   // @ts-ignore
        //   formData.append("file", this.files[i]["file"]);
        //
        //   this.files[i]["uploaded"] = "loading";
        //   await axios
        //     .post(
        //       `${apiUrl}/api/v1/files/upload/${
        //         this.propBucketName || this.propRelatedTableName
        //       }/${this.propRelatedFileName || this.propRelatedTableName}/${
        //         this.propRelatedTableRegisterId
        //       }`,
        //       formData,
        //       {
        //         headers: {
        //           Authorization: `Bearer ${mainStore.token}`,
        //           "Content-Type": "multipart/form-data",
        //         },
        //         // onUploadProgress: function (progressEvent) {
        //         //   this.progress = parseInt(
        //         //     Math.round((progressEvent.loaded / progressEvent.total) * 100),
        //         //   );
        //         // }.bind(this),
        //       },
        //     )
        //     .then(
        //       function () {
        //         // @ts-ignore
        //         this.files[i]["uploaded"] = true;
        //       }.bind(this),
        //     )
        //     .catch(
        //       function () {
        //         // @ts-ignore
        //         this.files[i]["uploaded"] = false;
        //       }.bind(this),
        //     );
        // }
        // this.uploadingFiles = "completed";
        // this.items = await this.getBucketObjectsByPrefix();
        // this.modelFiles = [];
        // this.files = [];
    }
    async getBucketObjectsByPrefix() {
        try {
            this.loadingData = true;
            // @ts-ignore
            const response = await api.getBucketObjectsByPrefix(mainStore.token, this.propBucketName || this.propRelatedTableName, this.propRelatedTableRegisterId, this.propRelatedFileName || this.propRelatedTableName);
            if (response) {
                let items = response.data;
                //  Oculta mockups para franchisee
                if (this.currentUser?.company_id !== 1) {
                    items = items.filter((i) => !i.blob.name.toLowerCase().includes('mockup'));
                }
                this.loadingData = false;
                return items;
            }
        }
        catch (e) {
            // @ts-ignore
            await mainStore.checkApiError(e);
        }
    }
    async mounted() {
        this.loadingData = true;
        // await mainStore.getUserProfile();
        // this.items = await this.getBucketObjectsByPrefix();
        const el = document.body.getElementsByClassName('v-data-table-header-mobile__wrapper');
        if (el) {
            // @ts-ignore
            el.innerHTML = 'teste';
        }
        this.loadingData = false;
    }
};
__decorate([
    Prop(String)
], UploadFilesComponent.prototype, "propDownloadTitle", void 0);
__decorate([
    Prop(String)
], UploadFilesComponent.prototype, "propRelatedTableName", void 0);
__decorate([
    Prop(String)
], UploadFilesComponent.prototype, "propRelatedFileName", void 0);
__decorate([
    Prop({ default: undefined })
], UploadFilesComponent.prototype, "propBucketName", void 0);
__decorate([
    Prop(String)
], UploadFilesComponent.prototype, "propRelatedTableRegisterId", void 0);
__decorate([
    Prop({ default: 'auto' })
], UploadFilesComponent.prototype, "propHeight", void 0);
__decorate([
    Prop({ default: false })
], UploadFilesComponent.prototype, "disabled", void 0);
__decorate([
    Prop({ default: false })
], UploadFilesComponent.prototype, "propReadOnly", void 0);
__decorate([
    Prop({ default: false })
], UploadFilesComponent.prototype, "hideDefaultFooter", void 0);
__decorate([
    Prop({ default: false })
], UploadFilesComponent.prototype, "propSmall", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], UploadFilesComponent.prototype, "propHideUploadBtn", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], UploadFilesComponent.prototype, "propHideTable", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], UploadFilesComponent.prototype, "propDense", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], UploadFilesComponent.prototype, "propOutlined", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], UploadFilesComponent.prototype, "propRounded", void 0);
__decorate([
    Prop(Object)
], UploadFilesComponent.prototype, "propFooter", void 0);
__decorate([
    Prop({ default: '' })
], UploadFilesComponent.prototype, "propPermitionExtensionFiles", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], UploadFilesComponent.prototype, "value", void 0);
__decorate([
    Prop({ type: Number, default: 5 })
], UploadFilesComponent.prototype, "propMaxSize", void 0);
__decorate([
    Watch('propPermitionExtensionFiles')
], UploadFilesComponent.prototype, "alterar", null);
UploadFilesComponent = __decorate([
    Component
], UploadFilesComponent);
export default UploadFilesComponent;
